import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import useNotifications from "@notifications"

export default function useProviderFormsList(providerId) {

    // Use toast
	const { toastNotification } = useNotifications()

    const provider = ref(providerId);
    const refFormsListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'start_date', sortable: true, label: 'MES/AÑO <br> EVALUACIÓN' },
        { key: 'state_name', sortable: true, label: 'ESTADO' },
        { key: 'factor_1', sortable: true, label: 'NIVEL DE <br> FACTURACIÓN' },
        { key: 'factor_2', sortable: true, label: 'CONTINUDAD <br> EN PRODUCCIÓN' },
        { key: 'factor_3', sortable: true, label: 'CANTIDAD <br>  MANO DE OBRA' },
        { key: 'factor_4', sortable: true, label: 'FRECUENCIA <br> DEL SERVICIO' },
        { key: 'factor_5', sortable: true, label: 'NIVEL <br> DE RIESGO' },
        { key: 'total', sortable: true, label: 'PUNTAJE <br> TOTAL' },
        { key: 'criticality', sortable: true, label: 'NIVEL DE <br> CRITICIDAD' },
        /* { key: 'email_sent_count', sortable: true, label: 'CANTIDAD DE <br> CORREOS ENVIADOS' }, */
        { key: 'createdAt', sortable: true, label: 'FECHA REGISTRO' },
        { key: 'actions', sortable: false, label: 'OPCIONES' }
    ];

    const perPage = ref(10);
    const totalForms = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [5, 10, 15, 20];
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);

    const statesFilter = ref(null);
    const conditionsFilter = ref(null);
    const startDateRangePickerFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refFormsListTable.value ? refFormsListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalForms.value,
        }
    });

    const refetchData = () => {
        refFormsListTable.value.refresh();
    };

    watch([currentPage, perPage, statesFilter, conditionsFilter, startDateRangePickerFilter], () => {
        refetchData();
    });

    const fetchForms = (ctx, callback) => {
        store
            .dispatch('app-provider-form/fetchForms', {
                id: provider.value,
                queryParams: {
                    perPage: perPage.value,
                    page: currentPage.value,
                    sortBy: sortBy.value,
                    sortDesc: isSortDirDesc.value,

                    states: statesFilter.value,
                    conditions: conditionsFilter.value,
                    startdate: startDateRangePickerFilter.value
                }
            })
            .then(response => {
                const { forms, total } = response.data;
                callback(forms);
                totalForms.value = total;
            })
            .catch(() => {
				toastNotification('Error al obtener la lista de formularios', false)
            });
    };

    return {
        fetchForms,
        tableColumns,
        perPage,
        currentPage,
        totalForms,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        refFormsListTable,

        refetchData,

        // Extra Filters
        statesFilter,
        conditionsFilter,
        startDateRangePickerFilter
    }
}